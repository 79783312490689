import React from "react";

const Title = ({ title, subTitle, addContainerClassname }) => {
  const baseStyle = `text-center ${subTitle ? "mb-12" : "mb-12 md:mb-20"}`;
  const combinedStyles = `${baseStyle} ${addContainerClassname}`;

  return (
    <div className={combinedStyles}>
      <h2 className="relative z-10 inline-block text-center text-xl font-bold xxs:text-[27px] xs:text-4xl">
        {title}
        <span className="absolute bottom-0 left-0 z-[-1] h-3 w-full bg-yellowGradient-dark"></span>
      </h2>
      {subTitle ? (
        <p className="mt-2 text-lg font-semibold">{subTitle}</p>
      ) : null}
    </div>
  );
};

export default Title;
