import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { RxCross2 } from "react-icons/rx";
import Slider from "react-slick";
import Button from "../Buttons/Button";
import {
  SiReact,
  SiMysql,
  SiSymfony,
  SiJavascript,
  SiPhp,
  SiTailwindcss,
  SiBootstrap,
  SiTrello,
  SiFigma,
  SiJirasoftware,
  SiPostman,
  SiGit,
  SiHtml5,
  SiCss3,
} from "react-icons/si";
import { MdDraw } from "react-icons/md";
import { TbApi } from "react-icons/tb";
import { RiPagesFill } from "react-icons/ri";

const ModalProject = ({ onClose, projet }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderTechnologyIcon = (technology) => {
    switch (technology) {
      case "HTML":
        return <SiHtml5 />;
      case "CSS":
        return <SiCss3 />;
      case "ReactJS":
        return <SiReact />;
      case "Symfony":
        return <SiSymfony />;
      case "JavaScript":
        return <SiJavascript />;
      case "PHP":
        return <SiPhp />;
      case "Twig":
        return <RiPagesFill />;
      case "TailwindCSS":
        return <SiTailwindcss />;
      case "Bootstrap":
        return <SiBootstrap />;
      case "API Platform":
        return <TbApi />;
      case "MySql":
        return <SiMysql />;
      default:
        return null;
    }
  };

  const renderToolsIcon = (tool) => {
    switch (tool) {
      case "Trello":
        return <SiTrello />;
      case "Jira":
        return <SiJirasoftware />;
      case "Figma":
        return <SiFigma />;
      case "draw.io":
        return <MdDraw />;
      case "Git":
        return <SiGit />;
      case "Postman":
        return <SiPostman />;
      default:
        return null;
    }
  };

  //! Permet de fermer le modal lors du clic sur le bouton retour : en neutralisant le bouton retour et en lui affectant le onClose
  useEffect(() => {
    const neutralizeBack = (callback) => {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
        callback();
      };
    };

    const revivalBack = () => {
      window.onpopstate = null;
    };

    neutralizeBack(onClose);

    return () => revivalBack();
  }, [onClose]);

  //! Fermeture du modal lors de l'appuie sur Echap
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-5 opacity-0 backdrop-blur-sm transition-opacity duration-300 ease-out"
      style={{ animation: "fadeIn 0.3s ease-out forwards" }}
      onClick={onClose}
    >
      <div
        className="relative mx-auto max-h-[90%] w-[100%] max-w-[900px] overflow-y-auto rounded-lg bg-backgroundColor px-8 py-12 opacity-0 transition-all duration-300 ease-out md:px-12"
        style={{ animation: "slideIn 0.3s ease-out forwards" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="absolute right-5 top-4 cursor-pointer text-xl font-black md:top-8 md:text-3xl"
          onClick={onClose}
        >
          <RxCross2 />
        </div>
        {/* Contenu du Modal */}
        {/* Slider */}
        <div className="flex flex-col gap-12 md:flex-row">
          <Slider {...settings} className="h-full md:w-1/3 md:self-center">
            {projet.images.map((image, index) => (
              <div key={index} className="flex">
                <img
                  src={image}
                  alt={`Présentation du site : ${projet.title}`}
                  className="mx-auto h-full max-h-[250px] w-full max-w-[370px] rounded-lg object-cover object-top md:max-h-[440px]"
                  loading="lazy"
                />
              </div>
            ))}
          </Slider>

          <div
            className={`md:w-${
              projet.cover ? "2/3" : "full"
            } flex grow flex-col`}
          >
            <div className="mb-4">
              {/* Nom du projet */}
              <h3 className="text-2xl font-bold">{projet.title}</h3>
              {/* Description du projet */}
              {projet.description && (
                <p className="mt-2 text-sm">{projet.description}</p>
              )}
            </div>
            {/* Mon rôle */}
            {projet.role && (
              <div className="mb-4 flex">
                <p className="text-xs">
                  <span className="font-bold">Mon rôle : </span>
                  {projet.role}
                </p>
              </div>
            )}
            {/* Statut */}
            {projet.status && (
              <div className="mb-4 flex">
                <p className="text-xs">
                  <span className="font-bold">Statut : </span>
                  {projet.status}
                </p>
              </div>
            )}
            {/* Techno */}
            {projet.technologies && (
              <div className="mb-4">
                <span className="text-xs font-bold">Stack Technique :</span>
                <div className="flex flex-wrap gap-1">
                  {projet.technologies.map((tech, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center gap-2 rounded-full border border-black px-2 py-1 text-xs font-semibold text-gray-700"
                    >
                      {renderTechnologyIcon(tech)}
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {/* Outils */}
            {projet.tools && (
              <div className="mb-8">
                <span className="text-xs font-bold">Outils :</span>
                <div className="flex flex-wrap gap-1">
                  {projet.tools.map((tool, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center gap-2 rounded-full border border-black px-2 py-1 text-xs font-semibold text-gray-700"
                    >
                      {renderToolsIcon(tool)}
                      {tool}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {/* Lien vers le projet */}
            {projet.url && (
              <div className="mt-auto">
                <Button href={projet.url} fullWidth={true} newTab>
                  Visiter {projet.title} 👀
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ModalProject;
