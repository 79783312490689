import React from "react";

const Button = ({
  addClassName,
  variant = "default",
  onClick,
  children,
  href,
  download,
  fullWidth = false,
  newTab = false,
}) => {
  const fullStyle = fullWidth ? "w-full text-center" : "";

  const baseStyle = `cursor-pointer rounded-full p-2 px-3 s:px-5 transition-transform duration-300 group-hover:translate-x-[-2px] group-hover:translate-y-[-2px] s:text-xl block text-center ${fullStyle}`;

  const variantStyles = {
    default:
      "bg-yellowGradient-dark font-semibold border-[3px] border-yellowGradient-dark",
    secondary: "bg-backgroundColor font-semibold border-[3px] border-black",
  };

  const combinedStyles = `${baseStyle} ${variantStyles[variant]} ${addClassName}`;

  const isLink = !!href;

  const linkProps = isLink
    ? {
        href: href,
        ...(newTab && { target: "_blank", rel: "noopener noreferrer" }),
        ...(download && { download }),
      }
    : {};

  const wrapperStyle = fullWidth ? "w-full" : "inline-block";

  const handleAnchorClick = (e) => {
    if (href && href.startsWith("#") && !newTab) {
      e.preventDefault();
      const targetElement = document.getElementById(href.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={`group relative ${wrapperStyle} before:absolute before:left-0.5 before:top-0.5 before:h-full before:w-full before:rounded-full before:opacity-0 before:transition-opacity before:duration-300 before:content-[''] hover:before:opacity-100 s:before:bg-black`}
    >
      {isLink ? (
        <a
          {...linkProps}
          className={combinedStyles}
          onClick={handleAnchorClick}
        >
          {children}
        </a>
      ) : (
        <button onClick={onClick} className={combinedStyles}>
          {children}
        </button>
      )}
    </div>
  );
};

export default Button;
