import React, { useState } from "react";
import {
  SiReact,
  SiMysql,
  SiSymfony,
  SiJavascript,
  SiPhp,
  SiTailwindcss,
  SiBootstrap,
  SiTrello,
  SiFigma,
  SiJirasoftware,
  SiPostman,
  SiGit,
  SiHtml5,
  SiCss3,
  SiNextdotjs,
  SiNodedotjs,
  SiPostgresql,
  SiJest,
  SiAdobexd,
  SiNotion,
} from "react-icons/si";
import { MdDraw } from "react-icons/md";
import { TbApi } from "react-icons/tb";
import { RiPagesFill } from "react-icons/ri";
import { PiFileSqlLight } from "react-icons/pi";
import { BsFiletypePhp } from "react-icons/bs";

const Skills = ({ skills }) => {
  const [isHovered, setIsHovered] = useState(null);

  const renderTechnologyIcon = (technology) => {
    switch (technology) {
      case "HTML":
        return <SiHtml5 />;
      case "CSS":
        return <SiCss3 />;
      case "ReactJS":
        return <SiReact />;
      case "Symfony":
        return <SiSymfony />;
      case "JavaScript":
        return <SiJavascript />;
      case "NextJS":
        return <SiNextdotjs />;
      case "NodeJS":
        return <SiNodedotjs />;
      case "PHP":
        return <SiPhp />;
      case "Twig":
        return <RiPagesFill />;
      case "TailwindCSS":
        return <SiTailwindcss />;
      case "Bootstrap":
        return <SiBootstrap />;
      case "API Platform":
        return <TbApi />;
      case "SQL":
        return <PiFileSqlLight />;
      case "MySql":
        return <SiMysql />;
      case "PostgreSQL":
        return <SiPostgresql />;
      case "Trello":
        return <SiTrello />;
      case "Jira":
        return <SiJirasoftware />;
      case "AdobeXD":
        return <SiAdobexd />;
      case "Figma":
        return <SiFigma />;
      case "draw.io":
        return <MdDraw />;
      case "Git":
        return <SiGit />;
      case "Postman":
        return <SiPostman />;
      case "Jest":
        return <SiJest />;
      case "PHPUnit":
        return <BsFiletypePhp />;
      case "Notion":
        return <SiNotion />;
      default:
        return null;
    }
  };

  return (
    <div
      className="grid grid-cols-1 gap-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3"
      data-aos="fade-up"
    >
      {skills.map((skill, index) => (
        <div
          key={skill.id}
          onMouseEnter={() => setIsHovered(index)}
          onMouseLeave={() => setIsHovered(null)}
          onFocus={() => setIsHovered(index)}
          onBlur={() => setIsHovered(null)}
          tabIndex="0"
          className="group relative mx-auto inline-block w-full max-w-[314px] before:absolute before:left-0 before:top-0 before:h-full before:w-full before:rounded-lg before:border-[2px] before:opacity-0 before:transition-opacity before:duration-300 before:content-[''] hover:before:opacity-100 s:before:bg-black"
        >
          <div className="mx-auto flex h-[400px] w-full max-w-[314px] cursor-pointer flex-col overflow-hidden rounded-lg border-[2px] border-black bg-backgroundColor px-5 py-8 transition-transform duration-300 group-hover:translate-x-[-4px] group-hover:translate-y-[-6px]">
            <div className="relative flex w-[calc(100%-40px)]">
              {/* Icone */}
              <div
                className={`absolute top-0 transition-[left] duration-700 ${
                  isHovered === index ? "left-full" : "left-0"
                }`}
              >
                {skill.icon &&
                  React.createElement(skill.icon, { size: "40px" })}
              </div>
            </div>
            <div className="h-full">
              {/* Titre */}
              <h3
                className={`mb-8 mt-[64px] min-h-[72px] text-base font-medium transition-all duration-700 xxs:text-xl xs:text-3xl`}
              >
                {skill.title}.
              </h3>
              <div class="relative">
                <div
                  class={`absolute flex w-[calc(200%+128px)] gap-[128px] transition-[left] duration-500 ${
                    isHovered === index ? "left-[calc(-100%-128px)]" : "left-0"
                  }`}
                >
                  {/* Description */}
                  <div class="flex-1">
                    <p>{skill.description}</p>
                  </div>
                  {/* technos */}
                  <div class="grid flex-1 grid-cols-3 gap-x-4 gap-y-8">
                    {skill.technologies.map((tech, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center justify-center gap-2 text-gray-800"
                      >
                        <span className="text-3xl">
                          {renderTechnologyIcon(tech)}
                        </span>
                        <span className="text-xs">{tech}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Skills;
