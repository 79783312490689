// Correction de l'import
import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FiGithub } from "react-icons/fi";
import { SiGmail } from "react-icons/si";

const SocialLinks = ({ desktop, mobile }) => {
  // Correction de la logique conditionnelle
  const relatedDesktop = desktop ? "hidden lg:flex lg:justify-around" : "";
  const relatedMobile = mobile ? "flex justify-center gap-8 mb-12 lg:hidden" : "";

  const containerStyle = desktop
    ? relatedDesktop
    : mobile
    ? relatedMobile
    : "flex justify-around";

  return (
    <div className={`${containerStyle}`}>
      <a
        href="https://www.linkedin.com/in/abdel-mansouri-565143112/"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer rounded-lg bg-backgroundColor p-2"
        aria-label="LinkedIn d'Abdel Mansouri"
      >
        <FaLinkedinIn size={20} />
      </a>
      <a
        href="https://github.com/AbdelMansouri"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer rounded-lg bg-backgroundColor p-2"
        aria-label="GitHub d'Abdel Mansouri"
      >
        <FiGithub size={20} />
      </a>
      <a
        href="mailto:abdel.mansouri.pro@gmail.com"
        className="cursor-pointer rounded-lg bg-backgroundColor p-2"
        aria-label="Contacter Abdel Mansouri par e-mail"
      >
        <SiGmail size={20} />
      </a>
    </div>
  );
};

export default SocialLinks;
