import React from "react";

const Section = ({
  children,
  fullWidth = false,
  padding,
  addContainerClassname,
  id,
}) => {
  const fullStyle = fullWidth ? "w-full" : "max-w-[1060px]";
  // Ici, on garde paddingStyle comme une chaîne conditionnelle
  const paddingStyle = padding
    ? `${padding}`
    : "px-5 xl:px-0 pb-[80px] md:pb-[175px] pt-[80px]";

  const baseStyle = `mx-auto ${fullStyle} ${paddingStyle}`;

  const combinedStyles = `${baseStyle} ${addContainerClassname}`;

  return (
    <section className={combinedStyles} id={id}>
      {children}
    </section>
  );
};

export default Section;
