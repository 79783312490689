import { BsDatabaseFillGear } from "react-icons/bs";
import { PiSealCheckFill } from "react-icons/pi";
import { MdDesignServices } from "react-icons/md";
import { FaProjectDiagram } from "react-icons/fa";
import { FaServer } from "react-icons/fa";
import { TbAppsFilled } from "react-icons/tb";

const skills = [
  {
    id: 1,
    title: "Développement Front-End",
    shortTitle: "Front",
    description:
      "Spécialisé dans la création d'interfaces utilisateur engageantes et réactives",
    icon: TbAppsFilled,
    technologies: [
      "HTML",
      "CSS",
      "TailwindCSS",
      "JavaScript",
      "ReactJS",
      "NextJS",
    ],
  },
  {
    id: 2,
    title: "Développement Back-End",
    shortTitle: "Back",
    description:
      "Expérience dans la construction et la gestion de back-ends robustes pour des applications web",
    icon: FaServer,
    technologies: ["NodeJS", "PHP", "Symfony", "API Platform"],
  },
  {
    id: 3,
    title: "Gestion de Bases de Données",
    shortTitle: "BDD",
    description: "Compétences en gestion et manipulation de bases de données",
    icon: BsDatabaseFillGear,
    technologies: ["SQL", "MySql", "PostgreSQL"],
  },
  {
    id: 4,
    title: "Testing et Assurance Qualité",
    shortTitle: "Test",
    description:
      "Aptitude à garantir la qualité et la performance des applications grâce à des tests rigoureux",
    icon: PiSealCheckFill,
    technologies: ["Jest", "PHPUnit", "Postman"],
  },
  {
    id: 5,
    title: "UI/UX Design",
    shortTitle: "UI/UX",
    description:
      "Compétences en conception d'interfaces utilisateur et expérience utilisateur",
    icon: MdDesignServices,
    technologies: ["Figma", "AdobeXD", "draw.io"],
  },
  {
    id: 6,
    title: "Gestion de projet",
    shortTitle: "Gestion",
    description:
      "Compétences en gestion de projet et en maintien de versions de code",
    icon: FaProjectDiagram,
    technologies: ["Trello", "Jira", "Git", "Notion"],
  },
];

export default skills;
