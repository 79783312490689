import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Section from "../common/Section";
import Button from "../Buttons/Button";
import SocialLinks from "../ui/SocialLinks";

const Footer = () => {
  const form = useRef();
  const [contactForm, setContactForm] = useState({
    name: "",
    firstName: "",
    email: "",
    message: "",
  });
  const [errorName, setErrorName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");

  const validateField = (name, value) => {
    switch (name) {
      case "name":
        setErrorName(!value.trim() ? "Le nom est requis." : "");
        break;
      case "firstName":
        setErrorFirstName(
          !value.trim()
            ? "Le prénom est requis pour que je puisse humaniser ma réponse 😁"
            : ""
        );
        break;
      case "email":
        if (!value) {
          setErrorEmail("L'email est requis.");
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          setErrorEmail("L'email est invalide.");
        } else {
          setErrorEmail("");
        }
        break;
      case "message":
        setErrorMessage(
          !value.trim() ? "Le message ne peut pas être vide." : ""
        );
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm({ ...contactForm, [name]: value });
    validateField(name, value);
  };

  const validateForm = () => {
    let formIsValid = true;

    Object.keys(contactForm).forEach((key) => {
      validateField(key, contactForm[key]);
      if (!contactForm[key].trim()) formIsValid = false;
    });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    emailjs
      .sendForm(
        "service_qtoekg",
        "template_t6arff6",
        form.current,
        "G4QIZ3lVU4q2iZgZM"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          showModalWithMessage("Message envoyé avec succès 😁", "success");
          setContactForm({ name: "", firstName: "", email: "", message: "" });
          setErrorName("");
          setErrorFirstName("");
          setErrorEmail("");
          setErrorMessage("");
        },
        (error) => {
          console.log("FAILED...", error.text);
          showModalWithMessage("Erreur lors de l'envoi du message 😔", "error");
        }
      );
  };

  const showModalWithMessage = (message, type) => {
    setModalMessage(message);
    setModalType(type);
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
      setModalMessage("");
      setModalType("");
    }, 3000);
  };

  return (
    <footer className="bg-black">
      <Section addContainerClassname="" padding="py-20 px-5">
        <div className="mb-20 text-center">
          <a
            href="/"
            aria-label="Retour à la page d'accueil"
            className={`z-20 select-none text-[50px] font-black text-backgroundColor`}
          >
            AM.
          </a>
        </div>
        <div className="mb-2 text-center font-inter lg:mb-12">
          <p className="text-xl font-black text-backgroundColor xxs:text-3xl xs:text-[40px] s:text-[50px]">
            Contactez-moi
          </p>
          <div className="flex justify-end">
            <div className="flex items-center gap-5">
              <div className="h-[2px] w-10 bg-stone-500 xxs:w-16 s:w-20 lg:h-[3px]"></div>
              <p className="font-black leading-none text-stone-500 xxs:text-xl xs:text-[30px] s:text-[35px]">
                Échangeons !
              </p>
            </div>
          </div>
        </div>

        <div className="mb-12 flex flex-col gap-5 lg:mb-24 lg:flex-row lg:gap-12">
          <form
            ref={form}
            onSubmit={handleSubmit}
            className="order-2 text-xs text-white xs:text-base lg:order-1 lg:min-w-[750px]"
            id="contact"
          >
            <div className="mb-4 flex gap-12">
              <div className="flex-1">
                <input
                  type="text"
                  name="name"
                  value={contactForm.name}
                  onChange={handleChange}
                  placeholder="Votre Nom"
                  className="w-full border-b border-gray-400 bg-transparent px-3 py-2 focus:border-gray-300 focus:outline-none"
                />
                {errorName && (
                  <p className="text-xs text-red-500">{errorName}</p>
                )}
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  name="firstName"
                  value={contactForm.firstName}
                  onChange={handleChange}
                  placeholder="Votre Prénom"
                  className="w-full border-b border-gray-400 bg-transparent px-3 py-2 focus:border-gray-300 focus:outline-none"
                />
                {errorFirstName && (
                  <p className="text-xs text-red-500">{errorFirstName}</p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                value={contactForm.email}
                onChange={handleChange}
                placeholder="Votre Email"
                className="w-full border-b border-gray-400 bg-transparent px-3 py-2 focus:border-gray-300 focus:outline-none"
              />
              {errorEmail && (
                <p className="text-xs text-red-500">{errorEmail}</p>
              )}
            </div>
            <div className="mb-4">
              <textarea
                name="message"
                value={contactForm.message}
                onChange={handleChange}
                placeholder="Votre Message"
                className="w-full border border-gray-400 bg-transparent px-3 py-2 focus:border-gray-300 focus:outline-none"
                rows="4"
              ></textarea>
              {errorMessage && (
                <p className="text-xs text-red-500">{errorMessage}</p>
              )}
            </div>
            <div className="flex justify-center">
              <Button addClassName="text-black" variant="secondary" fullWidth>
                Envoyer
              </Button>
            </div>
          </form>
          <div className="order-1 pt-8 lg:order-2">
            <p className="mb-8 text-center text-sm text-stone-500 lg:text-left">
              Vous êtes à la recherche d'une expertise en développement web pour
              renforcer votre équipe ou concrétiser une idée ? Discutons de
              comment je peux vous apporter ma passion et mes compétences pour
              réaliser vos projets.
            </p>
            <SocialLinks desktop />
          </div>
        </div>
        <SocialLinks mobile />
        <p className="text-center text-sm text-backgroundColor">
          Copyright All Right Reserved 2024 Abdel Mansouri
        </p>
      </Section>
      {showModal && (
        <div
          className={`fixed left-0 top-[100px] z-10 mx-10 max-w-[450px] rounded p-8 shadow-lg ${
            modalType === "success"
              ? "bg-yellowGradient-dark text-black"
              : "bg-red-300 text-red-800"
          }`}
        >
          <span className="mb-2 block text-3xl font-semibold">
            {modalType === "success"
              ? "Merci !"
              : modalType === "error"
              ? "Erreur"
              : ""}
          </span>
          <span className="block">{modalMessage}</span>
        </div>
      )}
    </footer>
  );
};

export default Footer;
