import React, { useEffect, useRef, useState } from "react";
import { CgMenuLeftAlt } from "react-icons/cg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [styleChanged, setStyleChanged] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const sections = [
    { name: "A propos", slug: "a-propos" },
    { name: "Projets", slug: "projets" },
    { name: "Compétences", slug: "competences" },
    { name: "Parcours", slug: "parcours" },
    { name: "Contact", slug: "contact" },
  ];
  const [isMenuFocused, setMenuIsFocused] = useState(false);
  const menuRef = useRef(null);
  const [delayedZIndex, setDelayedZIndex] = useState(false);

  // Fonction pour basculer l'état d'ouverture du menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Gestion du focus lors de l'ouverture du menu
  useEffect(() => {
    if (isMenuOpen) {
      menuRef.current.focus();
    }
  }, [isMenuOpen]);

  // Utilisation de l'effet pour gérer les animations de changement de style du menu
  useEffect(() => {
    let timeout;
    if (isMenuOpen) {
      timeout = setTimeout(() => {
        setStyleChanged(true);
      }, 500);
    } else {
      setStyleChanged(false);
      timeout = setTimeout(() => {
        setStyleChanged(false);
      }, 100);
    }
    return () => clearTimeout(timeout);
  }, [isMenuOpen]);

  // Gère le défilement de la page quand un clic survient sur un lien de la navbar
  const handleAnchorClick = (e) => {
    const href = e.currentTarget.getAttribute("href");
    if (href.startsWith("#")) {
      e.preventDefault();
      const targetElement = document.getElementById(href.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        if (window.innerWidth < 1024) {
          setIsMenuOpen(false);
        }
      }
    }
  };

  // Détection de la section active actuelle lors du scroll
  useEffect(() => {
    const handleScroll = () => {
      let currentSection = "";
      sections.forEach((section) => {
        const element = document.getElementById(section.slug);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= 0) {
            currentSection = section.slug;
          }
        }
      });
      setActiveSection(currentSection);
      console.log(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  // Gestion du focus et de l'accessibilité du menu
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && isMenuOpen) {
        setIsMenuOpen(false);
      }
      if (e.key === "Enter" && !isMenuOpen && isMenuFocused) {
        setIsMenuOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMenuOpen, isMenuFocused]);

  const handleMenuFocus = () => {
    setMenuIsFocused(true);
  };

  const handleMenuBlur = () => {
    setMenuIsFocused(false);
  };

  // Diffère le z-index du menu afin que le background ne passe pas sous le contenu
  useEffect(() => {
    if (!isMenuOpen) {
      const timer = setTimeout(() => {
        setDelayedZIndex(true);
      }, 900);

      return () => clearTimeout(timer);
    } else {
      setDelayedZIndex(false);
    }
  }, [isMenuOpen]);

  return (
    <>
      {/* Fond de la navbar - Mobile */}
      <nav
        className={`duration-900 fixed left-0 right-0 top-[-1px] z-50 flex h-[75px] max-w-[100vw] items-center justify-between bg-backgroundColor px-10 ease-in-out lg:px-12 xxl:bg-transparent ${
          isMenuOpen ? "xxl:z-20" : delayedZIndex ? "xxl:z-0" : ""
        }`}
      >
        {/* OVerlay flou - Mobile */}
        {isMenuOpen && (
          <div
            className="fixed left-0 top-0 z-10 h-screen w-screen backdrop-blur-sm lg:hidden"
            onClick={toggleMenu}
          ></div>
        )}

        {/* Logo */}
        <a
          href="/"
          aria-label="Retour à la page d'accueil"
          className={`logo z-20 select-none text-[40px] font-black ${
            isMenuOpen && styleChanged
              ? "text-black lg:text-white"
              : "text-black"
          }`}
        >
          AM<span className="dot inline-block">.</span>
        </a>
        <div className="relative select-none">
          {/* Icone burger */}
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="relative z-10"
            ref={menuRef}
            tabIndex="0"
            onFocus={handleMenuFocus}
            onBlur={handleMenuBlur}
          >
            <CgMenuLeftAlt
              size="35px"
              className={`relative z-40 cursor-pointer ${
                isMenuOpen ? "text-white" : "text-black lg:hover:text-white"
              }`}
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
            />
            {/* Fond menu navigation */}
            <div
              className={`absolute right-[-10px] top-[-10px] z-30 transition-all duration-500 ease-in-out lg:bg-black ${
                isMenuOpen
                  ? "h-screen w-[200px] translate-x-[37.5px] translate-y-[-10px] bg-black lg:h-[75px] lg:w-screen"
                  : "h-[55px] w-[55px] rounded-lg lg:rounded-full"
              } ${isHovered || isMenuOpen ? "scale-100" : "scale-0"}`}
            >
              {/* Liste du menu */}
              {isMenuOpen && styleChanged && (
                <ul className="right-0 top-0 mx-auto flex h-full max-w-[1020px] list-none flex-col justify-center gap-6 px-8 text-white lg:h-[75px] lg:flex-row lg:items-center lg:px-0 lg:text-lg">
                  {sections.map((section) => (
                    <li key={section.slug} className="">
                      <a
                        href={`#${section.slug}`}
                        onClick={handleAnchorClick}
                        className="group relative"
                      >
                        {section.name}
                        <span
                          className={`absolute bottom-[-3px] left-0 z-[-1] h-1 bg-yellowGradient-dark group-hover:w-full ${
                            activeSection === section.slug ? "w-full" : "w-0"
                          }`}
                        ></span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
