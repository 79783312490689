import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import career from "../../data/careerData";
import { TbTopologyStarRing3 } from "react-icons/tb";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function AsNavFor() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  // Configuration du slider de frise chronologique
  const slider2Settings = {
    asNavFor: nav1,
    ref: (slider) => (sliderRef2 = slider),
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "0",
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveSlideIndex(newIndex),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Fonctions pour avancer la slide
  const goToNextSlide = () => {
    sliderRef1.slickNext();
  };

  // Fonctions pour reculer la slide
  const goToPreviousSlide = () => {
    sliderRef1.slickPrev();
  };

  console.log(career);

  return (
    <div className="mt-12" data-aos="fade-up">
      <Slider
        asNavFor={nav2}
        ref={(slider) => (sliderRef1 = slider)}
        arrows={false}
        className="mb-2 md:mb-12"
      >
        {career.map((item) => (
          <div
            key={item.id}
            className="career-detail mb-8 cursor-grab flex-col items-center gap-8 px-5 text-center md:flex-row md:px-20 md:text-start"
          >
            <div className="flex h-[160px] items-center">
              {item.icon ? (
                <img
                  src={item.icon}
                  alt={`Logo de ${item.title}`}
                  size={160}
                  className="h-auto max-w-[160px] rounded-lg"
                />
              ) : (
                <TbTopologyStarRing3 size={160} />
              )}
            </div>
            <div>
              <p className="mb-2 text-xl font-bold xxs:text-3xl">
                {item.location}
              </p>
              <p className="mb-3 text-xs text-gray-600 xxs:text-sm">
                {item.yearDetail}
              </p>
              <p className="text-sm text-gray-800 xxs:text-base">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </Slider>

      <div className="relative">
        <button
          onClick={goToPreviousSlide}
          className="absolute left-[-6px] top-[49.32%] z-10 xl:left-[-11px]"
          aria-label="Parcours précédent"
        >
          <IoIosArrowBack size={24} className="text-gray-400" />
        </button>
        <button
          onClick={goToNextSlide}
          className="absolute right-[-6px] top-[49.32%] z-10 xl:right-[-11px]"
          aria-label="Parcours suivant"
        >
          <IoIosArrowForward size={24} className="text-gray-400" />
        </button>
        <Slider {...slider2Settings} className="">
          {career.map((item, index) => (
            <div key={item.id} className="text-center">
              <div className="cursor-pointer">
                {/* titre */}
                <div className="">
                  <p
                    className={`${
                      index === activeSlideIndex
                        ? "text-gray-700"
                        : "text-gray-400"
                    } text-xl font-bold uppercase`}
                  >
                    {item.title}
                    <br />
                    {item.title2 ? item.title2 : "\u00A0"}
                  </p>
                </div>
                {/* frise chronologique */}
                <div className="relative my-7">
                  <div
                    className={`${
                      index === activeSlideIndex
                        ? "bg-yellowGradient-dark"
                        : "bg-gray-350"
                    } mx-auto h-6 w-6 rounded-full`}
                  ></div>
                  <div className="absolute left-0 top-3 z-[-1] h-[1px] w-full bg-gray-300"></div>
                </div>
                {/* date */}
                <p
                  className={`text-3xl font-bold tracking-[-3px] ${
                    index === activeSlideIndex
                      ? "text-gray-700"
                      : "text-gray-350"
                  }`}
                >
                  {item.year}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default AsNavFor;
