import {
  JoystiqueLogo,
  Joystique1,
  Joystique2,
  Joystique3,
  Joystique4,
  Joystique5,
  Joystique6,
} from "../assets/Joystique";
import {
  FoteliaLogo,
  Fotelia1,
  Fotelia2,
  Fotelia3,
  Fotelia4,
  Fotelia5,
  Fotelia6,
} from "../assets/Fotelia";
import {
  LevelLogo,
  Level1,
  Level2,
  Level3,
  Level4,
  Level5,
  Level6,
} from "../assets/Level";
import {
  GowheelsLogo,
  Gowheels1,
  Gowheels2,
  Gowheels3,
  Gowheels4,
  Gowheels5,
} from "../assets/Gowheels";
import {
  CaesarisLogo,
  Caesaris1,
  Caesaris2,
  Caesaris3,
} from "../assets/Caesaris";
import {
  AngelfripLogo,
  Angelfrip1,
  Angelfrip2,
  Angelfrip3,
} from "../assets/Angelfrip";

const projets = [
  {
    id: 1,
    title: "Joystique",
    shortDescription: "Création d'une plateforme de jeux vidéo communautaire",
    cover: JoystiqueLogo,
    images: [
      Joystique1,
      Joystique2,
      Joystique3,
      Joystique4,
      Joystique5,
      Joystique6,
    ],
    url: "https://joystique.fr/",
    technologies: [
      "JavaScript",
      "ReactJS",
      "PHP",
      "Symfony",
      "TailwindCSS",
      "API Platform",
      "MySql",
    ],
    tools: ["Trello", "Figma", "draw.io", "Postman", "Git"],
    description:
      "Joystique est une plateforme communautaire dédiée aux passionnés de jeux vidéo. Le projet vise à créer un espace où les utilisateurs peuvent partager leurs expériences, découvrir de nouveaux jeux, proposer des jeux à intégrer à la plateforme et plein d'autres fonctionnalités que j'ai imaginées, conçues et développées moi-même.",
    role: "Développeur Full Stack",
    status: "v1 publiée",
  },
  {
    id: 2,
    title: "Fotelia",
    shortDescription: "Création d'un site hôtelier de luxe",
    cover: FoteliaLogo,
    images: [Fotelia1, Fotelia2, Fotelia3, Fotelia4, Fotelia5, Fotelia6],
    url: "https://fotelia.abdel-mansouri.com/",
    technologies: ["JavaScript", "PHP", "Symfony", "Bootstrap", "MySql"],
    tools: ["Trello", "Figma", "Git"],
    description:
      "Fotelia est le site web d'un hôtel de luxe fictif situé à Paris, conçu pour permettre aux clients de réserver des chambres en ligne. Ce projet a été développé dans le cadre de ma formation sur Symfony, mettant l'accent sur la gestion des chambres, de la réservation à la vérification de leur disponibilité.",
    role: "Développeur Full Stack",
    status: "Publié",
  },
  {
    id: 3,
    title: "Level",
    shortDescription: "Développement d'un e-commerce de mode",
    cover: LevelLogo,
    images: [Level1, Level2, Level3, Level4, Level5, Level6],
    url: "https://level.abdel-mansouri.com/",
    technologies: ["Symfony", "Twig", "Bootstrap"],
    description:
      "Level est un site fictif de vente de vêtements en ligne, inspiré par United Colors of Benetton. Le projet, développé pendant ma formation Symfony, met en œuvre des fonctionnalités e-commerce complètes, de la gestion des produits au processus de commande.",
    role: "Développeur Full Stack",
    status: "Publié",
  },
  {
    id: 4,
    title: "GoWheels",
    shortDescription: "Conception d'une plateforme de location de véhicules",
    cover: GowheelsLogo,
    images: [Gowheels1, Gowheels2, Gowheels3, Gowheels4, Gowheels5],
    url: "https://gowheels.abdel-mansouri.com/",
    technologies: ["Symfony", "Twig", "Bootstrap"],
    description:
      "GoWheels est un projet fictif de location de voiture visant à offrir une expérience utilisateur élégante pour des marques telles que Chevrolet, Mercedes et Audi. Réalisé au cours de ma formation Symfony, il intègre des fonctionnalités de réservation et de gestion de flotte.",
    role: "Développeur Full Stack",
    status: "Publié",
  },
  {
    id: 5,
    title: "AngelFrip",
    shortDescription: "Site de réservation de produits d'occasion",
    cover: AngelfripLogo,
    images: [Angelfrip1, Angelfrip2, Angelfrip3],
    technologies: ["JavaScript", "ReactJS", "PHP", "Symfony", "TailwindCSS"],
    tools: ["Jira", "Figma", "Postman", "Git"],
    description:
      "AngelFrip est un projet conçu pour une auto-entrepreneuse spécialisée dans la vente de vêtements d'occasion via Facebook Live. Ce site vise à étendre sa présence en ligne en permettant la présentation et la réservation de ses produits sur une plateforme dédiée. En tant que développeur Full Stack avec une spécialisation en Front-end, j'ai pris en charge le développement de la page d'accueil, en assurant une expérience utilisateur fluide et réactive grâce à React et TailwindCSS. J'ai également implémenté une barre de recherche dynamique, permettant aux utilisateurs de trouver facilement des articles spécifiques. Ma contribution s'étend au-delà du front-end, ayant travaillé sur des aspects significatifs du back-end avec Symfony. Cela inclut la gestion des articles, l'implémentation de filtres avancés pour affiner les recherches, et la mise en place de la logique métier pour les réservations.",
    role: "Développeur Full Stack, spécialisé Front",
    status: "En cours de développement",
  },
  {
    id: 6,
    title: "Caesaris",
    shortDescription: "Développement d'une boutique de mode élégante",
    cover: CaesarisLogo,
    images: [Caesaris1, Caesaris2, Caesaris3],
    url: "https://caesaris.abdel-mansouri.com/",
    technologies: ["PHP", "HTML", "CSS"],
    description:
      "Caesaris est un site fictif de vente de vêtements en ligne, évoquant l'élégance de Ralph Lauren. Développé en PHP pur, ce projet m'a permis de mettre en pratique mes compétences en développement web en construisant ma première plateforme e-commerce.",
    role: "Développeur Full Stack",
    status: "Publié",
  },
];

export default projets;
