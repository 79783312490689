import Doranco from "../assets/Career/Doranco.png";
import Joystique from "../assets/Career/Joystique.png";
import Perso from "../assets/Career/AbdelMansouri.png";
import DUT from "../assets/Career/DUT.png";
import UPS from "../assets/Career/UPS.png";
import ENGIE from "../assets/Career/ENGIE.png";
import SFR from "../assets/Career/SFR.png";
import MAIF from "../assets/Career/MAIF.png";
import OC from "../assets/Career/OpenClassrooms.png";

const career = [
  {
    id: 1,
    title: "Formation",
    title2: "DWWM",
    year: 2023,
    location: "Doranco ecole sup'",
    description:
      "Titre - Développeur web et web mobile (RNCP37674). Formation en développement front-end et back-end, incluant des projets concrets.",
    yearDetail: "Mars 2023 - Septembre 2023",
    icon: Doranco,
  },
  {
    id: 2,
    title: "Développeur",
    title2: "FullStack",
    year: 2023,
    location: "Joystique",
    description: "Développement de la plateforme Joystique, un projet ambitieux qui m'a permis d'obtenir le titre DWWM (RNCP 5)",
    yearDetail: "Septembre 2023 - Décembre 2023",
    icon: Joystique,
  },
  {
    id: 3,
    title: "Développeur",
    title2: "Open to work",
    year: 2024,
    location: "Développeur Indépendant",
    description:
      "Je réalise des projets pour le compte de TPE / PME, allant de la conception de sites web à des applications personnalisées.",
    yearDetail: "Septembre 2023 - Aujourd'hui",
    icon: Perso,
  },
  {
    id: 4,
    title: "DUT",
    title2: "GEA",
    year: 2014,
    location: "IUT Ponsan",
    description:
      "Obtention du DUT (Diplôme universitaire de technologie) en gestion des entreprises et des administrations",
    yearDetail: "2014 - 2016",
    icon: DUT,
  },
  {
    id: 5,
    title: "Licence",
    title2: "Management",
    year: 2016,
    location: "Université Paul Sabatier",
    description: "Obtention de la Licence en Management, orienté gestion de projet",
    yearDetail: "2016 - 2017",
    icon: UPS,
  },
  {
    id: 6,
    title: "Commercial",
    title2: "Itinérant",
    year: 2017,
    location: "Engie",
    description:
      "Développement commercial sur un secteur géographique défini, j'ai œuvré à l'expansion de la clientèle et à la fidélisation des comptes existants.",
    yearDetail: "2017 - 2018",
    icon: ENGIE,
  },
  {
    id: 7,
    title: "Conseiller",
    title2: "commercial",
    year: 2018,
    location: "SFR",
    description:
      "Accompagnement et conseil des clients dans le choix de solutions télécom adaptées à leurs besoins, contribuant ainsi à l'atteinte des objectifs de vente de l'agence.",
    yearDetail: "2018 - 2021",
    icon: SFR,
  },
  {
    id: 8,
    title: "Conseiller",
    title2: "de vente",
    year: 2021,
    location: "MAIF",
    description:
      "Expertise dans la vente de produits d'assurance, en mettant l'accent sur la personnalisation des conseils offerts aux adhérents pour renforcer leur satisfaction et leur fidélité.",
    yearDetail: "Mars 2021 - Septembre 2022",
    icon: MAIF,
  },
  {
    id: 9,
    title: "Développeur",
    title2: "Autodidacte",
    year: 2022,
    location: "OpenClassrooms",
    description:
      "Début de l'apprentissage en autodidacte avec des cours en ligne sur OpenClassrooms, focalisés sur le développement web et mobile et la gestion de projet.",
    yearDetail: "Septembre 2022 - Mars 2023",
    icon: OC,
  },
];

export default career;
