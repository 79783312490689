import React, { useEffect, useState } from "react";
import Button from "../components/Buttons/Button";
import Profile from "../assets/Profile.png";
import { IoMdDownload } from "react-icons/io";
import Title from "../components/Title/Title";
import ModalProject from "../components/Modal/ModalProject";
import projets from "../data/projetsData";
import skills from "../data/skillsData";
import Skills from "../components/Skills/Skills";
import AsNavFor from "../components/Slider/AsNavFor";
import Section from "../components/common/Section";
import Dev from "../assets/Dev.jpg";
import { FaArrowDown } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(null);

  // Fonction pour ouvrir le modal avec l'ID du projet
  const openModal = (id) => setIsModalOpen(id);

  // Fonction pour fermer le modal
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
    <div className="">
      {/*//!----------------------------------- Présentation */}

      <Section
        padding="px-5 xl:px-0 py-[75px]"
        addContainerClassname="flex h-dvh max-h-[1250px] min-h-[750px] w-full flex-col items-center justify-center lg:flex-row"
      >
        <div className="slide-in-right mx-auto flex h-[40%] max-w-[400px] flex-col justify-center pt-3 s:h-1/2 lg:order-2 lg:h-auto lg:w-2/5 lg:pb-12 lg:pt-0">
          <img
            src={Profile}
            alt="Portrait d'Abdel Mansouri"
            className="h-full w-full object-contain object-center"
          />
        </div>
        <div className="flex h-[60%] max-w-[636px] flex-col justify-center pt-5 s:h-1/2 lg:order-1 lg:h-auto lg:w-3/5 lg:pt-0">
          <h1 className="slide-in-left font-semibold xxxs:text-xl xxs:text-3xl xs:text-4xl xs:font-normal s:text-5xl">
            Bonjour, je suis Abdel{" "}
            <span className="relative inline-block">
              Développeur Web
              <div className="absolute bottom-0 left-[-3px] -z-10 h-5 bg-yellowGradient-dark xxxs:w-[179px] xxs:w-[265px] xs:w-[315px] s:w-[420px]"></div>
            </span>
          </h1>
          <p className="slide-in-bottom my-5">
            Bienvenue sur mon portfolio ! Vous pouvez découvrir mes projets et
            en apprendre plus sur moi
          </p>
          <div className="slide-in-bottom flex flex-col gap-4 xs:flex-row">
            <Button
              addClassName="flex items-center justify-center gap-3"
              variant="secondary"
              href="#a-propos"
            >
              A propos de moi <FaArrowDown size={16} className="fa-bounce" />
            </Button>
            <Button href="#contact">Me contacter.</Button>
          </div>
        </div>
      </Section>
      {/*//!----------------------------------- A propos */}

      <Section
        padding="pt-[100px] px-5 xl:px-0 pb-[80px] md:pb-[175px]"
        id="a-propos"
      >
        <Title title="A propos." />
        <div className="flex flex-col justify-between gap-12 md:flex-row md:gap-12">
          <div className="md:w-1/3" data-aos="fade-right">
            <img
              src={Dev}
              alt="Abdel Mansouri au travail"
              className="mx-auto h-full max-h-[350px] rounded-lg"
              loading="lazy"
            />
          </div>
          <div className="md:w-2/3" data-aos="fade-up">
            <p className="mb-4 text-lg font-semibold">
              Bonjour, Je suis Mansouri Abdel
            </p>
            <p className="text-base">
              <span className="">
                Développeur web avec un parcours distinctif combinant
                compétences techniques et expérience en gestion de la relation
                client. Formé à Doranco et fort d'expériences significatives
                chez ENGIE et MAIF, je suis orienté vers la création de
                solutions web fonctionnelles et performantes. Passionné et
                adaptable, je cherche constamment à intégrer innovation et
                efficacité dans mes développements.
              </span>
            </p>
            <div className="my-7 h-[1px] w-full bg-gray-400"></div>
            <div className="">
              <div className="mb-7 flex flex-col justify-between s:flex-row">
                <div className="">
                  <p className="">
                    <span className="text-xs font-semibold uppercase">
                      Nom :
                    </span>{" "}
                    Abdel Mansouri
                  </p>
                  <p className="">
                    <span className="text-xs font-semibold uppercase">
                      âge :
                    </span>{" "}
                    30 Ans
                  </p>
                </div>
                <div className="">
                  <p className="">
                    <span className="text-xs font-semibold uppercase">
                      Job :
                    </span>{" "}
                    Développeur Web & Mobile
                  </p>
                  <p className="">
                    <span className="text-xs font-semibold uppercase">
                      Ville :
                    </span>
                    Toulouse
                  </p>
                </div>
              </div>
              <Button
                addClassName="flex items-center justify-center gap-2"
                fullWidth
                href={`${process.env.PUBLIC_URL}/Developpeur-Web-Mansouri.pdf`}
                download="Developpeur-Web-Mansouri.pdf"
              >
                <IoMdDownload /> Télécharger CV
              </Button>
            </div>
          </div>
        </div>
      </Section>
      {/*//!----------------------------------- Projets */}

      <Section id="projets">
        <Title
          title="Mes projets."
          subTitle="Découvrez une sélection de quelques-uns de mes projets."
        />
        <div
          className="grid grid-cols-1 gap-8 gap-y-32 pb-20 sm:grid-cols-2 lg:grid-cols-3"
          data-aos="fade-up"
        >
          {projets.map((projet) => (
            <div
              key={projet.id}
              tabIndex="0"
              className="group relative mx-auto max-w-[314px] cursor-pointer rounded-lg border shadow-lg"
              onClick={() => openModal(projet.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  openModal(projet.id);
                }
              }}
            >
              <div className="h-[250px] w-full overflow-hidden rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-105">
                {/* Filtre noir reste visible même au survol */}
                <div className="absolute left-0 top-0 h-full w-full rounded-lg bg-black opacity-5"></div>
                <img
                  src={projet.cover}
                  alt={`Projet ${projet.title}`}
                  className="h-full w-full object-cover object-top"
                />
              </div>
              <div className="absolute bottom-[-60px] left-1/2 w-[90%] -translate-x-1/2 rounded-lg border border-black bg-backgroundColor p-4">
                <h3 className="relative inline-block text-lg font-bold">
                  {projet.title}
                  <span className="absolute bottom-1 left-0 -z-10 h-2 w-0 bg-yellowGradient-dark transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h3>
                <p>{projet.shortDescription}</p>
                <p className="mt-2 text-end text-[10px] italic text-gray-500 group-hover:underline">
                  En savoir +
                </p>
              </div>
            </div>
          ))}
        </div>
      </Section>
      {/* Modal pour les détails du projet */}
      {isModalOpen && (
        <ModalProject
          onClose={closeModal}
          projet={projets.find((p) => p.id === isModalOpen)}
        />
      )}
      {/*//!----------------------------------- Compétences */}

      <Section id="competences">
        <Title
          title="Mes compétences."
          subTitle="Parcourez mes expertises en développement web."
        />
        <Skills skills={skills} />
      </Section>
      {/*//!----------------------------------- Parcours */}

      <Section padding="px-5 lg:px-0 pb-60 pt-[80px]" id="parcours">
        <Title title="Mon parcours." />
        <AsNavFor />
      </Section>
    </div>
  );
};

export default Home;
